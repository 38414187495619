<template>
  <div>
    <div class="spinner" :style="spinner_style"></div>
    <div class="spinner-text" :style="text_style" v-if="message.length > 0">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    'size': {
      default: 32
    },
    'speed': {
      type: Number,
      default: 0.8
    },
    'spacing': {
      type: Number,
      default: 4
    },
    'message': {
      type: String,
      default: ''
    },
    'font-size': {
      type: Number,
      default: 13
    },
  },
  computed: {
    size_px() {
      switch (this.size) {
        case 'tiny':
          return 12
        case 'small':
          return 16
        case 'medium':
          return 32
        case 'large':
          return 48
        case 'big':
          return 64
        case 'huge':
          return 96
        case 'massive':
          return 128
      }
      return this.isNumber(this.size) ? this.size : 32
    },
    text_font_size() {
      switch (this.size) {
        case 'tiny':
        case 'small':
        case 'medium':
        case 'large':
        case 'big':
        case 'huge':
        case 'massive':
          return Math.min(Math.max(Math.ceil(this.size_px * 0.4), 11), 32)
      }
      return this.isNumber(this.fontSize) ? this.fontSize : 13
    },
    spinner_style() {
      return {
        'margin': '0 auto',
        'border-radius': '100%',
        'border': '3px solid #2e5576',
        'border-top': '3px solid #ae7935',
        'width': this.size_px + 'px',
        'height': this.size_px + 'px',
        'animation': 'spinner-spin ' + this.speed + 's linear infinite'
      }
    },
    text_style() {
      return {
        'margin-top': '4px',
        'color': '#555',
        'font-size': this.text_font_size + 'px',
        'text-align': 'center'
      }
    }
  },
  methods: {
    isNumber: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    }
  }
}
</script>

<style>
.spinner {
  transition: all 0.3s linear;
}

@keyframes spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>